import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "loading-container"
};
import { ref } from 'vue';
export default {
  __name: 'index',
  setup(__props, {
    expose: __expose
  }) {
    const show = ref(false);
    const showLoading = () => {
      show.value = true;
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", () => {}, true);
    };
    const hideLoading = () => {
      show.value = false;
      let mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "";
      document.removeEventListener("touchmove", mo, true);
    };
    __expose({
      show,
      showLoading,
      hideLoading
    });
    return (_ctx, _cache) => {
      return show.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("div", {
        class: "loader"
      }, null, -1), _createElementVNode("div", {
        class: "tips"
      }, "正在快马加鞭的加载中....", -1)]))) : _createCommentVNode("", true);
    };
  }
};