import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

// ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 加载
import '@/utils/title'

// 全局引入样式文件 tailwindcss
import '@/assets/output.css'
// 动画
import '@/assets/css/animation.css'
// 阿里图标
import '@/assets/icon/ali_iconfont.css'
// Y滚动条
import '@/assets/css/scrollbar.css'

/*
富文本
 */
import {config, XSSPlugin} from 'md-editor-v3'
import 'md-editor-v3/lib/style.css'

import screenfull from 'screenfull';

import katex from 'katex';
import 'katex/dist/katex.min.css';

import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

import mermaid from 'mermaid';

import highlight from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';

import * as prettier from 'prettier';
import parserMarkdown from 'prettier/plugins/markdown';
/*
全局组件
 */
// 后台-按钮边框
import rightToolbar from "@/components/rightToolbar/index.vue";
// 加载
import Loading from '@/components/loading/loading'

config({
    // markdownItPlugins(plugins) {
    //     return [
    //         ...plugins,
    //         {
    //             type: 'xss',
    //             plugin: XSSPlugin,
    //             options: {
    //                 extendedWhiteList: {
    //                     img: ['onerror'], // 保持原有的img标签配置
    //                 }
    //             },
    //         },
    //     ];
    // },
    editorExtensions: {
        prettier: {
            prettierInstance: prettier,
            parserMarkdownInstance: parserMarkdown,
        },
        highlight: {
            instance: highlight,
        },
        screenfull: {
            instance: screenfull,
        },
        katex: {
            instance: katex,
        },
        cropper: {
            instance: Cropper,
        },
        mermaid: {
            instance: mermaid,
        },
    }
});


const app = createApp(App);

/*
全局组件
 */
//  rightToolbar 组件
app.component('rightToolbar', rightToolbar);


app.use(router)
    .use(store)
    .use(ElementPlus)
    .use(Loading)
    .mount('#app')
