// 图标枚举

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 根据图标名获取图标实例
export const getInstance = (iconName) => {
    // return iconEnum[toLowerCaseFirstLetter(iconName)] || iconEnum['grid']
    return iconEnum[toLowerCaseFirstLetter(iconName)]
}

// 图标枚举
let iconEnum = {}

/**
 * 初始化枚举
 */
export const initEnum = () => {

    let data = {}

    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        let itemKey = toLowerCaseFirstLetter(key)
        data = {
            ...data,
            [itemKey]: component
        }
    }
    iconEnum = data
}

// 首字母小写
const toLowerCaseFirstLetter = (str) => {
    if (!str || typeof str !== 'string' || str.length === 0) {
        return str;
    }
    return str.charAt(0).toLowerCase() + str.slice(1);
}
