import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-row items-center"
};
import { defineComponent, onMounted, ref } from 'vue';
import { getInstance } from "@/utils/iconEnum";
import { success } from "@/utils/elMessage";
// 显隐数据

export default {
  __name: 'index',
  props: {
    showSearch: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array
    },
    isCopySearch: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: true
    },
    gutter: {
      type: Number,
      default: 10
    }
  },
  emits: ['isCopy', 'update:showSearch', 'queryTable'],
  setup(__props, {
    emit: __emit
  }) {
    const value = ref([]);
    // 弹出层标题
    const title = ref('显示/隐藏');
    // 是否显示弹出层
    const open = ref(false);
    // 复制
    const isCopy = ref(true);

    // props
    const props = __props;

    // emit
    const emit = __emit;
    onMounted(() => {
      // 显隐列初始默认隐藏列
      for (let item in props.columns) {
        if (props.columns[item].visible === false) {
          value.value.push(parseInt(item));
        }
      }
      const isCopyLocal = localStorage.getItem('is_copy');
      if (isCopyLocal === undefined || isCopyLocal === null || isCopyLocal === '') {
        localStorage.setItem('is_copy', '1');
        isCopy.value = true;
      } else {
        isCopy.value = isCopyLocal === '1';
      }
      emit('isCopy', isCopy.value);
    });
    const style = defineComponent(() => {
      const ret = {};
      if (this.gutter) {
        ret.marginRight = `${this.gutter / 2}px`;
      }
      return ret;
    });

    // 复制
    const handleIsCoy = () => {
      if (isCopy.value) {
        localStorage.setItem('is_copy', '0');
        success('已关闭复制');
        isCopy.value = false;
      } else {
        localStorage.setItem('is_copy', '1');
        success('已开启复制');
        isCopy.value = true;
      }
      emit('isCopy', isCopy.value);
    };
    // 搜索
    const toggleSearch = () => {
      emit('update:showSearch', !props.showSearch);
    };
    // 刷新
    const refresh = () => {
      emit('queryTable');
    };
    // 右侧列表元素变化
    const dataChange = data => {
      for (let item in props.columns) {
        const key = props.columns[item].key;
        props.columns[item].visible = !data.includes(key);
      }
    };
    // 打开显隐列dialog
    const showColumn = () => {
      open.value = true;
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_tooltip = _resolveComponent("el-tooltip");
      const _component_el_transfer = _resolveComponent("el-transfer");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock("div", {
        class: "top-right-btn",
        style: _normalizeStyle(_unref(style))
      }, [_createElementVNode("div", _hoisted_1, [__props.search ? (_openBlock(), _createBlock(_component_el_tooltip, {
        key: 0,
        class: "item",
        effect: "dark",
        content: __props.showSearch ? '隐藏搜索' : '显示搜索',
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          size: "default",
          circle: "",
          icon: _unref(getInstance)('search'),
          onClick: _cache[0] || (_cache[0] = $event => toggleSearch())
        }, null, 8, ["icon"])]),
        _: 1
      }, 8, ["content"])) : _createCommentVNode("", true), __props.isCopySearch ? (_openBlock(), _createBlock(_component_el_tooltip, {
        key: 1,
        class: "item",
        effect: "dark",
        content: isCopy.value ? '关闭复制' : '开启复制',
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          size: "default",
          circle: "",
          icon: _unref(getInstance)('documentCopy'),
          onClick: _cache[1] || (_cache[1] = $event => handleIsCoy())
        }, null, 8, ["icon"])]),
        _: 1
      }, 8, ["content"])) : _createCommentVNode("", true), _createVNode(_component_el_tooltip, {
        class: "item",
        effect: "dark",
        content: "刷新",
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          size: "default",
          circle: "",
          icon: _unref(getInstance)('refresh'),
          onClick: _cache[2] || (_cache[2] = $event => refresh())
        }, null, 8, ["icon"])]),
        _: 1
      }), __props.columns ? (_openBlock(), _createBlock(_component_el_tooltip, {
        key: 2,
        class: "item",
        effect: "dark",
        content: "显隐列",
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          size: "default",
          circle: "",
          icon: _unref(getInstance)('menu'),
          onClick: _cache[3] || (_cache[3] = $event => showColumn())
        }, null, 8, ["icon"])]),
        _: 1
      })) : _createCommentVNode("", true)]), _createVNode(_component_el_dialog, {
        title: title.value,
        visible: open.value,
        "append-to-body": ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_transfer, {
          titles: ['显示', '隐藏'],
          modelValue: value.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => value.value = $event),
          data: __props.columns,
          onChange: dataChange
        }, null, 8, ["modelValue", "data"])]),
        _: 1
      }, 8, ["title", "visible"])], 4);
    };
  }
};