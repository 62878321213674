/**
 * 包装消息提示
 */
import {ElMessage} from "element-plus";

const message = (params) => {
    ElMessage({
        message: params.msg,
        type: params.type,
        plain: true,
        grouping: true,
        showClose: true,
        duration: params.duration || 2000
    })
}

/**
 * 成功提示
 * @param params 值
 */
export const success = (params) => {
    if (typeof params === 'string') {
        message({msg: params, type: 'success'})
        return
    }
    params = {
        ...params,
        msg: params.msg || '成功操作',
        type: 'success'
    }
    message(params)
}

/**
 * 警告提示
 * @param params 值
 */
export const warning = (params) => {
    if (typeof params === 'string') {
        message({msg: params, type: 'warning'})
        return
    }
    params = {
        ...params,
        msg: params.msg || '警告',
        type: 'warning'
    }
    message(params)
}

/**
 * 提示
 * @param params 值
 */
export const info = (params) => {
    if (typeof params === 'string') {
        message({msg: params, type: 'info'})
        return
    }
    params = {
        ...params,
        msg: params.msg || '提示',
        type: 'info'
    }
    message(params)
}

/**
 * 错误提示
 * @param params 值
 */
export const error = (params) => {
    if (typeof params === 'string') {
        message({msg: params, type: 'error'})
        return
    }
    params = {
        ...params,
        msg: params.msg || '错误操作',
        type: 'error'
    }
    message(params)
}
