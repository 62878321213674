// src/router/index.js
import {createRouter, createWebHistory} from 'vue-router';

const uiLayout = () => import('../components/ui-layout.vue')
const adminLayout = () => import('../components/adminLayout/index.vue')

const routes = [
    // 前台
    {
        path: '/',
        name: 'uiLayout',
        component: uiLayout,
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('../view/ui/home/index'),
                meta: {
                    title: '首页'
                }
            },
            {
                path: 'article/:id',
                name: 'article',
                component: () => import('../view/ui/article/index'),
                meta: {
                    title: '文章'
                }
            },
            {
                path: 'label/:id',
                name: 'label',
                component: () => import('../view/ui/label/index'),
                meta: {
                    title: '文章标签'
                }
            },
            {
                path: 'sort/:id',
                name: 'sort',
                component: () => import('../view/ui/sort/index'),
                meta: {
                    title: '文章分类'
                }
            },
            {
                path: 'photo',
                name: 'photo',
                component: () => import('../view/ui/photo/index'),
                meta: {
                    title: '相册'
                }
            },
            {
                path: 'photo/list/:id',
                name: 'photoList',
                component: () => import('../view/ui/photo/list.vue'),
                meta: {
                    title: '相册列表'
                }
            },
            {
                path: 'friendChain',
                name: 'friendChain',
                component: () => import('../view/ui/friendChain/index.vue'),
                meta: {
                    title: '友联'
                }
            },
            {
                path: 'favorite',
                name: 'favorite',
                component: () => import('@/view/ui/favorite/index.vue'),
                meta: {
                    title: '收藏夹'
                }
            }
        ]
    },
    // 后台
    {

        path: '/admin',
        name: 'adminLayout',
        component: adminLayout,
        redirect: '/admin/index',
        children: [
            {
                path: 'index',
                name: 'index',
                component: () => import('../view/admin/home/index'),
                meta: {
                    title: '首页',
                    icon: 'house'
                }
            },
            {
                path: 'webSetting',
                name: 'webSetting',
                component: () => import('../view/admin/webSetting/index'),
                meta: {
                    title: '网站设置',
                    icon: 'setting'
                }
            },
            {
                path: 'user',
                name: 'user',
                component: () => import('../view/admin/user/index'),
                meta: {
                    title: '用户管理',
                    icon: 'user'
                }
            },
            {
                path: 'category',
                name: 'category',
                component: () => import('../view/admin/category/index'),
                meta: {
                    title: '分类管理',
                    icon: 'memo'
                }
            },
            {
                path: 'label',
                name: 'label',
                component: () => import('../view/admin/label/index'),
                meta: {
                    title: '标签管理',
                    icon: 'collectionTag'
                }
            },
            {
                path: 'article',
                name: 'article',
                component: () => import('../view/admin/article/index'),
                meta: {
                    title: '文章管理',
                    icon: 'document'
                }
            },
            {
                path: 'articleAdd',
                name: 'articleAdd',
                component: () => import('../view/admin/article/add.vue'),
                meta: {
                    title: '文章添加',
                    icon: '',
                    hide: true
                }
            },
            {
                path: 'comment',
                name: 'comment',
                component: () => import('../view/admin/comment/index'),
                meta: {
                    title: '评论管理',
                    icon: 'edit'
                }
            },
            {
                path: 'resource',
                name: 'resource',
                component: () => import('../view/admin/resource/index'),
                meta: {
                    title: '资源管理',
                    icon: 'paperclip'
                }
            },
            {
                path: 'config',
                name: 'config',
                component: () => import('../view/admin/config/index'),
                meta: {
                    title: '配置管理',
                    icon: 'setUp'
                }
            },
            {
                path: 'log',
                name: 'log',
                redirect: '/admin/log/operation',
                meta: {
                    title: '日志管理',
                    icon: 'notebook'
                },
                children: [
                    {
                        path: 'operation',
                        name: 'operation',
                        component: () => import('../view/admin/log/operation/index'),
                        meta: {
                            title: '操作日志',
                            icon: ''
                        }
                    },
                    {
                        path: 'loginLog',
                        name: 'loginLog',
                        component: () => import('../view/admin/log/loginLog/index'),
                        meta: {
                            title: '登录日志',
                            icon: ''
                        }
                    },
                ]
            }
        ]

    },
    // 登录
    {
        path: '/login',
        name: 'Login',
        component: () => import('../view/login/index.vue'),
        meta: {
            title: '登录'
        }
    },
    // 404
    {
        path: '/404',
        name: '404',
        component: () => import('../view/404/index.vue')
    },
    // 通配
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'

    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from) => {
})
// 后置守卫
router.afterEach((to, from) => {
    if (to.path !== from.path) {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
})

export {routes as staticRoutes}; // 导出路由配置
export default router;
